import CartStorage from "@/scripts/services/cartStorage.service";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import CartService from "@/scripts/services/cart.service";
import CampaignService from "@/scripts/services/campaign.service";
import PaymentInstallment from "@/scripts/services/paymentInstallment.service";

// Router
import router from "../../router";

const CartModule = {
    state: {
        cartService: new CartService(),
        campaignService: new CampaignService(),
        paymentInstallment: new PaymentInstallment(),
        cartItems: [],
        cart: Object,
        recurrence: 1,
        reachedMinimumValue: false,
        itemToAdd: null,
        projectsInCart: [],
        paymentInstallments: {},
        lastSell: null,
        selectedGift: null,
        openBonus: false,
        currentAppreciation: null,
        installments: [],
        selectedInstallment: null,
        checkoutItems: [],
        mergedCart: false,
        paymentDay: null,
        payrollInstallment: null,
        campaignDonationProduct: null,
    },
    mutations: {
        ADD_TO_CART: (state, payload) => {
            CartStorage.addToCart(payload.item, payload.isLogged);
            state.cart = CartStorage.getCart();
        },
        ALTER_DONATION_PRODUCT: (_, payload) => {
            CartStorage.alterCartCampaignDonationProduct(
                payload.item,
                payload.isLogged
            );
        },
        CLEAR_CAMPAIGN_PRODUCT: () => {
            CartStorage.removeCampaignProduct();
        },
        REMOVE_TO_CART: (state, payload) => {
            CartStorage.removeToCart(payload);         
            state.cart = CartStorage.getCart();
        },
        UPDATE_CART: (state, payload) => {
            CartStorage.clearCart();
            CartStorage.setCart(payload.item);
            state.campaignDonationProduct = payload.item.campaignProduct;
            state.cart = CartStorage.getCart();
        },
        CLEAR_CART: (state) => {
            CartStorage.clearCart();
            state.cart = CartStorage.getCart();
        },
        REFRESH_CART: (state) => {
            state.cart = CartStorage.getCart();
            state.campaignDonationProduct = state.cart?.campaignProduct
        },
        SET_CART_PAYMENT_METHOD: (state, payload) => {
            state.cart.paymentMethod = payload;
            state.cart.paymentMethodId = PaymentMethod[payload];
        },
        SET_REACHED_MINIMUM_VALUE: (state, payload) => {
            state.reachedMinimumValue = payload;
        },
        SET_ITEM_TO_ADD: (state, payload) => {
            state.itemToAdd = payload;
        },
        LOAD_LAST_SELL: (state) => {
            state.cartService
                .getLastSell(state.cart.userId, state.cart.campaignId)
                .then((response) => {
                    state.lastSell = response;
                });
        },
        SET_SELECTED_GIFT: (state, payload) => {
            state.selectedGift = payload;
        },
        SET_CART_ITEMS: (state, payload) => {
            state.cart.cartItems = payload;
        },
        SET_CHECKOUT_REQUEST: (state, payload) => {
            const cart = CartStorage.getCart();
            cart.checkoutRequest = payload;
            CartStorage.clearCart();
            CartStorage.setCart(cart);
            state.cart = CartStorage.getCart();
        },
        CREATE_INSTALLMENTS: (state, payload) => {
            state.installments = [];
            state.paymentInstallment
                .getFirstInstallments(
                    payload.campaignId,
                    payload.value,
                    payload.paymentMethodId
                )
                .then((response) => {
                    state.installments = response;
                });
            state.selectedInstallment = 1;
        },
        SET_SELECTED_INSTALLMENT: (state, payload) => {
            state.selectedInstallment = payload;
            const cart = CartStorage.getCart();
            cart.creditCardParcelament = payload;
            CartStorage.clearCart();
            CartStorage.setCart(cart);
            state.cart = CartStorage.getCart();
        },
        SET_SELECTED_PAYMENT_DAY: (state, payload) => {
            state.paymentDay = payload;
        },
        SET_CHECKOUT_ITEMS: (state, payload) => {
            state.checkoutItems = payload;
        },
        MERGED_CART: (state, payload) => {
            state.mergedCart = payload;
        },
        SET_PAYMENT_INSTALLMENTS: (state, payload) => {
            state.paymentInstallments = { ...payload};
        },
        SET_PAYROLL_INSTALLMENT: (state, payload) => {
            state.payrollInstallment = payload;
        },
    },
    getters: {
        cartLocal: (state) => {
            return state.cart;
        },
        mergedCart: (state) => {
            return state.mergedCart;
        },
        cartItems: (state) => {            
            state.cart = CartStorage.getCart();
            if(!state.cart) return []
            return state.cart.cartItems;
        },
        alreadyExistsInCart: (state) => (item) => {
            if (state.cart && state.cart.cartItems) {
                const a =
                    state.cart.cartItems.filter((cartItem) => {
                        return item.donationProduct && cartItem.donationProduct
                            ? cartItem.donationProduct &&
                                  cartItem.donationProduct.name ==
                                      item.donationProduct.name
                            : item.project.fullName ==
                                  cartItem?.project?.fullName;
                    }).length != 0;
                return a;
            }

            return false;
        },
        campaignDonationProductAlreadyOnCart:
            (state) => (donationProductId) => {
                if (state.cart)
                    return (
                        state.cart.campaignDonationProduct == donationProductId
                    );
                return false;
            },
        campaignDonationProduct: (state) => {
            return state.campaignDonationProduct;
        },
        reachedMinimumValue: (state) => {
            return state.reachedMinimumValue;
        },
        itemToAdd: (state) => {
            return state.itemToAdd;
        },
        lastSell: (state) => {
            return state.lastSell;
        },
        isInMaintenanceValue: (state, getters) => {
            if (
                state.lastSell &&
                getters.checkoutValue &&
                state.lastSell.totalValue > getters.checkoutValue
            ) {
                const differenceBetween =
                    state.lastSell.totalValue - getters.checkoutValue;

                const products = getters.campaignGifts.filter((item) => {
                    return (
                        differenceBetween >= item.initialValue &&
                        differenceBetween <= item.finalValue &&
                        item.active === true
                    );
                });

                return {
                    isInMaintenanceValue: true,
                    productValueForAppreciation: differenceBetween,
                    products: products,
                };
            } else {
                return {
                    isInMaintenanceValue: false,
                };
            }
        },
        selectedGift: (state) => {
            return state.selectedGift;
        },
        currentAppreciation: (state, getters) => {
            if (getters.campaignProducts) {
                state.cart.campaignDonationProduct =
                    getters.campaignProducts.find((item) => {
                        const value = getters.checkoutValue;
                        if (item.finalValue !== "0")
                            return (
                                value >= item.initialValue &&
                                value <= item.finalValue
                            );
                        else return value >= item.initialValue;
                    });
            }

            return state.cart.campaignDonationProduct;
        },
        checkoutRequest: (state) => {
            return state.cart.checkoutRequest;
        },
        installments: (state) => {
            return state.installments;
        },
        selectedInstallment: (state) => {
            return state.selectedInstallment;
        },
        paymentDay: (state) => {
            return state.paymentDay
        },
        showCheckoutItems: (state) => {
            return state.checkoutItems;
        },
        paymentInstallments: (state) => {
            return state.paymentInstallments;
        },
        payrollInstallment: (state) => {
            return state.payrollInstallment;
        },
        campaignDonationProductAnswers: (state) => {
            if(!state.campaignDonationProduct) return [];
            return state.campaignDonationProduct.campaignProductQuestionList?.map((question) => {
                return {
                    questionId: question.id,
                    campaignProductId: question.campaignProductId,
                    answer: question.answer
                }
            });
        },
        simulationOverrideValue: (state) => {
            return state.simulationOverrideValue
        }
    },
    actions: {
        addToCart(state, value) {
            var payload = { item: value, isLogged: state.getters.logged };
            state.commit("ADD_TO_CART", payload);
            state.commit("REFRESH_CART");
        },
        alterCampaignDonationProduct(state, value) {
            state.commit("ALTER_DONATION_PRODUCT", {
                item: value,
                isLogged: state.getters.logged,
            });
        },
        removeToCart(state, value) {
            var payload = { item: value, isLogged: state.getters.logged };
            state.commit("REMOVE_TO_CART", payload);
            state.commit("REMOVE_FROM_CHECKOUT", value);
            state.commit("SET_CHECKOUT_VALUE");
            if (state.getters.cartItems.length <= 0)
                state.commit("CLEAR_CAMPAIGN_PRODUCT");
            state.commit("REFRESH_CART");
        },
        updateCart(state, value) {
            state.commit("UPDATE_CART", {
                item: value,
                isLogged: state.getters.logged,
            });
        },
        clearCart(state) {
            state.commit("CLEAR_CART");
        },
        refreshCart(state) {
            state.commit("REFRESH_CART");
        },
        setReachedMinimumValue(state, payload) {
            state.commit("SET_REACHED_MINIMUM_VALUE", payload);
        },
        setItemToAdd(state, payload) {
            state.commit("SET_ITEM_TO_ADD", payload);
        },
        getLastSell(state) {
            state.commit("LOAD_LAST_SELL");
        },
        setSelectedGift(state, payload) {
            state.commit("SET_SELECTED_GIFT", payload);
        },
        setCartItems(state, payload) {
            state.commit("SET_CART_ITEMS", payload);
        },
        setCheckoutRequest(state, payload) {
            state.commit("SET_CHECKOUT_REQUEST", payload);
        },
        createInstallments: (state, payload) => {
            state.commit("CREATE_INSTALLMENTS", payload);
        },
        setInstallment: (state, payload) => {
            state.commit("SET_SELECTED_INSTALLMENT", payload);
        },
        setPaymentDay: (state, payload) => {
            state.commit("SET_SELECTED_PAYMENT_DAY", payload);
        },
        setCheckoutItems: (state, payload) => {
            state.commit("SET_CHECKOUT_ITEMS", payload);
        },
        setMergedCart: (state, payload) => {
            state.commit("MERGED_CART", payload);
        },
        setPaymentInstallments: (state, payload) => {
            state.commit("SET_PAYMENT_INSTALLMENTS", payload);
        },
        setPayrollInstallment: (state, payload) => {
            state.commit("SET_PAYROLL_INSTALLMENT", payload);
        },
    },
};

export default CartModule;
